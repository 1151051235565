// dirty flag for state, indicates unsaved changes to state. 
import $ from 'jquery'
import './autocomplete/jquery-ui.min.js';
import './autocomplete/jquery-ui.min.css';

var state = {}
let unsaved = false
let leaving = true
let idleTimer
let autoSaveTimer
const IDLE_DURATION = 5 * 1000 * 60 // 10 Minutes
const AUTO_SAVE_DELAY = 30 * 1000 * 60 // 30 Minutes

window.onbeforeunload = function (event) {
    // If there is unsaved changes to state, and user tries to leave, give them a modal and chance to save. 
    if (unsaved && leaving) {
        // Give option to Save, for users already logged in. 
        document.querySelector('[data-rvt-dialog="modal-save-confirmation"]').open()
        // Most browsers ignore this text, but it does trigger the generic "Leave site?" alert. Then our modal is presented. 
        event.returnValue = "You have unsaved changes."
    }
}

document.addEventListener('rvtDialogClosed', function (event){
    // Clean up add alerts when cart is closed. 
    if(event.target.id == "my-list"){
        document.getElementById("cart-add-alert-success").classList.add("hidden")
    }
})

window.addEventListener('afterprint', function (event){
    // Restore scenarios and course list after printing (for normal full page printing via browser UI)
    let savedSection = document.getElementById("saved_scenario_section")
    if(savedSection)
        savedSection.classList.remove("print_hide")
    
    let earnedSection = document.getElementById("earned_credit_section")
    if(earnedSection)
        earnedSection.classList.remove("print_hide")
})


export default {
    setKeepAliveTimer: function () {
        idleTimer = setTimeout(function () {
            iu.ajaxGet("ping.html").subscribe({
                raw: true, next: function (response) {
                    clearTimeout(idleTimer)
                    trex.setKeepAliveTimer()
                }
            })
        }, IDLE_DURATION);
    },
    setAutoSaveConfirmation: function () {
        // Reset timer. 
        clearTimeout(autoSaveTimer)
        // If there is any unsavedchanges to state, set timeout til save confirmation modal. 
        if (unsaved) {
            autoSaveTimer = setTimeout(function () {
                document.querySelector('[data-rvt-dialog="modal-save-confirmation"]').open()
            }, AUTO_SAVE_DELAY)
        }
    },
    init: function (data) {
        console.log("init from " + (data ? "server DB" : "localStorage"))
        // Pre-populating Course/Exam List on page load. 
        // Prefer server data for cart, passed in via server rendered JSON in <script> block on request.html. 
        if (data) {
            state = data
            unsaved = false
        } else {
            // Local storage of state for non IU users with no ability to save anything to our DBs, with benefit of state 
            // surviving page refresh. Try to restore local data for cart if no server data exists. 
            try {
                // Attempt to get local state json (stored as string)
                let storedState = localStorage.getItem("state")
                // Attempt to parse string into usable JSON object. 
                if (storedState && storedState !== null) {
                    state = JSON.parse(storedState)
                    if (state.colleges === undefined  || state.tests === undefined )
                        unsaved = false
                    else
                        unsaved = true
                }        
                // If we are a user without server data but selections that were previously only in localStorage, save local results         
                if(env.user && unsaved){
                    this.saveList()
                }
            } catch (err) {
                // Clean slate/state if malformed data json string. 
                state = {}
                unsaved = false
            }
        }
        if( env.user ){
            this.setKeepAliveTimer();
        }
        
        let campus = document.getElementById("select-campus").value
        if (campus) {
            state.campus = campus
        }

        $("#text-country").autocomplete({
            source: lstcountries,
            minLength: 0,
            select: function (event, ui) {
                var label = ui.item.label;
                if (label === "No results found") {
                    let country = document.getElementById("hidden-country")
                    country.value = ""
                    event.preventDefault();
                } else {
                    trex.selectCountry(ui.item.id)
                }
            },
            response: function (event, ui) {
                if (!ui.content.length) {
                    var noResult = { value: "", label: "No results found" };
                    ui.content.push(noResult);
                }
            },
            change: function (event, ui) {
                if (!ui.item) {
                    $(event.target).val("");
                }
            }
        }).focus(function () {
            let textState = $("#text-state")
            textState.prop('disabled', true)
            $(this).autocomplete("search", "");
        });

        $("#text-state").autocomplete({
            source: lststates,
            minLength: 0,
            select: function (event, ui) {
                var label = ui.item.label;
                if (label === "No results found") {
                    let polit = document.getElementById("hidden-state")
                    polit.value = ""
                    event.preventDefault();
                } else {
                    trex.selectState(ui.item.id)
                }
            },
            response: function (event, ui) {
                if (!ui.content.length) {
                    var noResult = { value: "", label: "No results found" };
                    ui.content.push(noResult);
                }
            },
            change: function (event, ui) {
                if (!ui.item) {
                    $(event.target).val("");
                    let textCollege = $("#text-college")
                    textCollege.autocomplete( "option", "source", lstcolleges );
                }
            }
        }).focus(function () {
            $(this).autocomplete("search", "");
        });

         $("#text-college").autocomplete({
             source: lstcolleges,
             minLength:1,
             select: function( event, ui ) {
                var label = ui.item.label;
                if (label === "No results found") {
                    let school = document.getElementById("hidden-college")
                    school.value = ""
                    event.preventDefault();
                }else{
                    document.getElementById("spinning-subject").classList.remove("hidden")
                    trex.selectCollege(ui.item)   
                }            
             },
             response: function(event, ui) {
                 if (!ui.content.length) {
                 var noResult = { value:"",label:"No results found" };
                 ui.content.push(noResult);
             }
             },    
             change: function (event, ui) {
                 if(!ui.item){
                     $(event.target).val("");                
                 }
             }
         });

        $("#text-subject").autocomplete({
            source: null,
            minLength: 0,
            select: function (event, ui) {
                var label = ui.item.label;
                if (label === "No results found") {
                    let subject = document.getElementById("hidden-subject")
                    subject.value = ""
                    event.preventDefault();
                }else{
                    document.getElementById("spinning-course").classList.remove("hidden")                
                    trex.selectSubject(ui.item.id)               
               }
            },
            response: function (event, ui) {
                if (!ui.content.length) {
                    var noResult = { value: "", label: "No results found" };
                    ui.content.push(noResult);
                }
            },
            change: function (event, ui) {
                if (!ui.item) {
                    $(event.target).val("");
                }
            }
        }).focus(function () {
            $(this).autocomplete("search", "");
        });

        $("#text-course").autocomplete({
            source: null,
            minLength: 0,
            select: function (event, ui) {
                var label = ui.item.label;
                if (label === "No results found") {
                    let course = document.getElementById("hidden-course")
                    course.value = ""
                    event.preventDefault();
                } else {
                    trex.selectCourse(ui.item)
                }
            },
            response: function (event, ui) {
                if (!ui.content.length) {
                    var noResult = { value: "", label: "No results found" };
                    ui.content.push(noResult);
                }
            },
            change: function (event, ui) {
                if (!ui.item) {
                    $(event.target).val("");
                }
            }
        }).focus(function () {
            $(this).autocomplete("search", "");
        });;

        $("#text-test").autocomplete({
            source: lsttests,
            minLength:0,
            select: function( event, ui ) {
                var label = ui.item.label;
                if (label === "No results found") {
                    let test = document.getElementById("hidden-test")
                    test.value=""
                    event.preventDefault();
                }else{
                    trex.selectTest(ui.item.id)               
                }
            },
            response: function(event, ui) {
                if (!ui.content.length) {
                var noResult = { value:"",label:"No results found" };
                ui.content.push(noResult);
            }
            },    
            change: function (event, ui) {
                if(!ui.item){
                    $(event.target).val("");                
                }
            }
        }).focus(function () {
            let textComponent = $("#text-component")
            textComponent.prop('disabled',true)  
            textComponent.val("") 
            $(this).autocomplete("search", "");
        });

        $("#text-component").autocomplete({
            source: lstcomponents,
            minLength:0,
            select: function( event, ui ) {
                document.getElementById("score-info").classList.add("hidden")
                var label = ui.item.label;
                if (label === "No results found") {
                    let component = document.getElementById("hidden-component")
                    component.value=""
                    event.preventDefault();
                }else{
                    trex.selectTestComponent(ui.item)               
                }
            },
            response: function(event, ui) {
                if (!ui.content.length) {
                var noResult = { value:"",label:"No results found" };
                ui.content.push(noResult);
            }
            },    
            change: function (event, ui) {
                if(!ui.item){
                    $(event.target).val("");                
                }
            }
        }).focus(function () {
            $(this).autocomplete("search", "");
        });

        this.renderCart()
        this.updateControls()
    },
    logout: function () {
        iu.navigateTo('request.html?logout=true')
    },
    login: function () {
        leaving = false
        let inst = document.getElementById("select-campus").value
        let params = {}

        if (inst) {
            params = {
                campus: inst
              }
        }

        let url = iu.getUrl("request.html",params)
        iu.navigateTo(url)
    },
    offbound: function (element) {
        leaving = false
    },
    home: function () {
        let url = iu.getUrl("request.html")
        leaving = false
        iu.navigateTo(url)
    },
    printAll: function () {
        window.print();
    },
    printScenario: function () {
        // Hide course list while printing scenarios. 
        let earnedCreditSection = document.getElementById("earned_credit_section")
        earnedCreditSection.classList.add("print_hide")
        window.print();
    },
    printCourseList: function () {
        // Hide scenarios while printing course list. 
        let scenarioSection = document.getElementById("saved_scenario_section")
        scenarioSection.classList.add("print_hide")
        window.print();
    },
    addMoreCredits: function () {
        let url = iu.getUrl("request.html")
        leaving = false
        iu.navigateTo(url)
    },
    profile: function () {
        if( !env.user ){
            this.login()
        }

        let url = iu.getUrl("profile.html")

        let scenarioButton = document.getElementById("button-view-scenario")
        if(scenarioButton){
            scenarioButton.classList.add("rvt-button--loading")
            scenarioButton.setAttribute("aria-busy", true)
        }

        leaving = false
        iu.navigateTo(url)
    },
    selectCampus: function (element) {
        let campus = element.value
        if (campus) {
            state.campus = campus
        }else{
            delete state.campus
        }
        document.getElementById('summary_section').classList.add("hidden")

        let scenario_button = document.getElementById("button-scenario")
        if(scenario_button !=null){
            scenario_button.setAttribute("disabled", true)
            document.getElementById('save-scenario-alert').classList.add("hidden")
        }

        // Select campus updates the Apply List Buttons (disabled vs enabled)
        updateApplyListButton()
    },
    selectCountry: function (value) {
        let textCollege = $("#text-college")
        textCollege.val("")
        let textSubject = $("#text-subject")
        textSubject.val("")
        textSubject.prop('disabled', true)
        let textCourse = $("#text-course")
        textCourse.val("")
        textCourse.prop('disabled', true)
        //reset item college
        if (state.collegeitm)
            state.collegeitm = []
        //reset item state
        if (state.courseitm)
            state.courseitm = []

        let country = document.getElementById("hidden-country")
        country.value = value

        let polity = document.getElementById("text-state")
        if (value !== "USA") {
            polity.value = ""
            polity.disabled = true
            let hiddenPolity = document.getElementById("hidden-state")
            hiddenPolity.value = ""
        } else {
            polity.disabled = false
        }

        var filtered = lstcolleges.filter(scol => scol.country === value);
        textCollege.autocomplete("option", "source", filtered);
        document.getElementById("disabled-add-course-error").classList.add("hidden")
        document.getElementById("course-add-alert-success").classList.add("hidden")
    },
    selectState: function (value) {
        let textCollege = $("#text-college")
        textCollege.val("")
        let textSubject = $("#text-subject")
        textSubject.val("")
        textSubject.prop('disabled', true)
        let textCourse = $("#text-course")
        textCourse.val("")
        textCourse.prop('disabled', true)
        //reset item college
        if (state.collegeitm)
            state.collegeitm = []
        //reset item state
        if (state.courseitm)
            state.courseitm = []

        let polity = document.getElementById("hidden-state")
        polity.value = value

        var filtered = lstcolleges.filter(scol => scol.state === value);
        filtered.sort(function (a, b) {
            return a.label.localeCompare(b.label)
        })
        textCollege.autocomplete("option", "source", filtered);
        document.getElementById("disabled-add-course-error").classList.add("hidden")
        document.getElementById("course-add-alert-success").classList.add("hidden")
    },
    selectCollege: function (item) {
        let textSubject = $("#text-subject")
        textSubject.val("")
        textSubject.prop('disabled', true)
        let textCourse = $("#text-course")
        textCourse.val("")
        textCourse.prop('disabled', true)
        //reset item state
        if (state.courseitm)
            state.courseitm = []

        let school = document.getElementById("hidden-college")
        school.value=item.id

        let data = {}
        data.college = school.value
        let college = {}
        college.country = item.country
        college.name = item.value
        college.city = item.city
        college.state = item.state
        college.id = item.id

        // Create College item if it doesn't exist. 
        if (!state.collegeitm)
            state.collegeitm = {}
        state.collegeitm = college
        document.getElementById("disabled-add-course-error").classList.add("hidden")
        document.getElementById("course-add-alert-success").classList.add("hidden")
         iu.ajaxGet("criteria.html", data).subscribe({
             raw: true, next: function (response) {
                textSubject.prop('disabled',false)
                textSubject.autocomplete( "option", "source", response );
                document.getElementById("spinning-subject").classList.add("hidden")          
            }
         })
    },
    selectSubject: function (value) {
        let textCourse = $("#text-course")
        textCourse.val("")
        textCourse.prop('disabled', true)
        //reset item state
        if (state.courseitm)
            state.courseitm = []

        let college = document.getElementById("hidden-college")
        let data = {}
        data.college = college.value
        data.subject = value
        document.getElementById("disabled-add-course-error").classList.add("hidden")
        document.getElementById("course-add-alert-success").classList.add("hidden")
        iu.ajaxGet("criteria.html", data).subscribe({
            raw: true, next: function (response) {
                textCourse.prop('disabled', false)
                response.sort(function (a, b) {
                    return a.label.localeCompare(b.label)
                })
                textCourse.autocomplete("option", "source", response);
                document.getElementById("spinning-course").classList.add("hidden")               
            }
        })
    },
    selectCourse: function (item) {
        console.log("selectCourse")
        // Update Add course button and set courseitm state. 
        if (item !=null && item !=="") {
            document.getElementById("addCourseButton").removeAttribute("aria-disabled")
            // Create course item array if it doesn't exist. 
            if (!state.courseitm)
                state.courseitm = {}

            let course = {}
            course.subject = item.subject
            course.courseNbr = item.coursenbr
            course.descr = item.descr
            course.credits = item.credits
            state.courseitm = course

        } else {
            state.courseitm = []
            document.getElementById("addCourseButton").setAttribute("aria-disabled", "true")
        }
        document.getElementById("disabled-add-course-error").classList.add("hidden")
        document.getElementById("course-add-alert-success").classList.add("hidden")
    },
    selectTest: function (value) {
        let textComponent = $("#text-component")
        textComponent.val("")

        let test = document.getElementById("hidden-test")
        test.value=value

        var filtered = lstcomponents.filter(tst => tst.testid === value);
        filtered.sort(function (a, b) {
            return a.label.localeCompare(b.label)
        })

        textComponent.autocomplete( "option", "source", filtered );
        textComponent.prop('disabled',false)
        document.getElementById("disabled-add-test-error").classList.add("hidden")
        document.getElementById("test-add-alert-success").classList.add("hidden")
    },
    selectTestComponent: function (item) {
        let component = document.getElementById("hidden-component")
        component.value = item.id

        let scoreInfoDiv = document.getElementById("score-info");
        document.getElementById("select-score").value = ""
        document.getElementById("addTestButton").setAttribute("aria-disabled", "true");
        // Update min/max info blurb 
        if (item.minscore || item.maxscore) {
            scoreInfoDiv.classList.remove("hidden");
            document.getElementById("score-info-min").innerText = item.minscore
            document.getElementById("score-info-max").innerText = item.maxscore
        }
        document.getElementById("disabled-add-test-error").classList.add("hidden")
        document.getElementById("test-add-alert-success").classList.add("hidden")
    },
    handleTestScoreChange: function (element) {

        // Get Min Max from component option data attributes. 
        let min = parseInt(document.getElementById("score-info-min").innerText)
        let max = parseInt(document.getElementById("score-info-max").innerText)
        let val = parseInt(element.value)

        let addTestBtn = document.getElementById("addTestButton");

        // All values are proper numbers perform check. 
        if (!isNaN(val) && !isNaN(min) && !isNaN(max) && val >= min && val <= max) {
            // If valid value, enable add button. 
            addTestBtn.removeAttribute("aria-disabled");
            document.getElementById("disabled-add-test-error").classList.add("hidden")
        } else {
            // otherwise disable add button. 
            addTestBtn.setAttribute("aria-disabled", "true");
            let msg = document.getElementById("disabled-add-test-error-msg")
            msg.innerHTML = "You must enter a valid Score in order to proceed."
        }
    },
    addCourse: function (element) {
        if(element.getAttribute('aria-disabled')){
            let polity = document.getElementById("text-state").value
            let college = document.getElementById("text-college").value
            let subject = document.getElementById("text-subject").value
            let course = document.getElementById("text-course").value
            let msg = document.getElementById("disabled-add-course-error-msg")
            if(!polity && !college && !subject && !course){
                msg.innerHTML = "You must select a State, College/School, Subject, and Course to proceed."
            }else if(!college && !subject && !course){
                msg.innerHTML = "You must select a College/School, Subject, and Course to proceed."
            }else if(!subject && !course){
                msg.innerHTML = "You must select a Subject and Course to proceed."
            }else if(!course){
                msg.innerHTML = "You must select a Course to proceed."
            }            
            document.getElementById("disabled-add-course-error").classList.remove("hidden")
            return
        } else {
            document.getElementById("disabled-add-course-error").classList.add("hidden")
        }

        let college = {} 
        let school = document.getElementById("hidden-college")
        college.id=school.value

        // Create Colleges associative array if it doesn't exist. 
        if (!state.colleges)
            state.colleges = {}

        // Create specific college entry in associative array if it doesn't exist. 
        if (!state.colleges[college.id]) {
            state.colleges[college.id] = state.collegeitm
            state.colleges[college.id].courses = Array()
        }

        // Get course itm. 
        let course = {}
        if (!state.courseitm)
            console.log("No selected course to add")
        else {
            course.subject = state.courseitm.subject
            course.courseNbr = state.courseitm.courseNbr
            course.descr = state.courseitm.descr
            course.credits = state.courseitm.credits
        }

        // Add the course to the specific college entry's courses array, if it doesn't exist. 
        if (state.colleges[college.id].courses.findIndex(c => (c.subject == course.subject && c.courseNbr == course.courseNbr)) == -1) {

            // Add course to the specifica college's course list. 
            state.colleges[college.id].courses.push(course)

            // Sort by subject then courseNbr (as numeric), unless Descr is preferred.
            state.colleges[college.id].courses.sort(function (a, b) {
                return a.subject.localeCompare(b.subject) || a.courseNbr.localeCompare(b.courseNbr, undefined, { numeric: true })
            })

            // Unsaved change. 
            unsaved = true
        }

        // Reset course
        let textCourse = $("#text-course")
        textCourse.val("")
        state.courseitm = []

        // document.getElementById("addCourseDetail").innerText = "Selected Course"
        document.getElementById("addCourseButton").setAttribute("aria-disabled", "true")

        // Successfully Added course alert. 
        document.getElementById("alert-course").innerText = "\"" + course.subject + "-" + course.courseNbr + " " + course.descr + "\""
        document.getElementById("course-add-alert-success").classList.remove("hidden")

        let cartMsg = `<em>\"${course.subject}-${course.courseNbr} ${course.descr}\"</em> added.`
        document.getElementById("cart-added-message").innerHTML = cartMsg
        document.getElementById("cart-add-alert-success").classList.remove("hidden")

        if(!document.getElementById("summary_section").classList.contains("hidden")){
            document.getElementById('alert-course-below').innerText = " and applied to the campus selection below"
        } else {
            document.getElementById('alert-course-below').innerText = ""
        }
        
        // Having updated state, render cart (and show in non-Mobile)
        setTimeout(function () {
            trex.renderCart()                
            if(env.user && unsaved){
                trex.saveCourse(college.id, course.subject, course.courseNbr, course.credits)
            } else {
                trex.updateControls()
            }
            //work around for when list doesn't stay open 
            const myList = document.querySelector('[data-rvt-dialog="my-list"]')
            myList.open()
        }, 0)
    },
    saveCourse: function(extOrgId, subject, courseNbr, credits){
        let data = {}
        data.courses = []
        let row = {}
        row.extOrgId = extOrgId
        row.subject = subject
        row.courseNbr = courseNbr
        row.credits = credits
        data.courses.push(row)
        let url = iu.getUrl("request.html")
        iu.ajaxPost(url, data).subscribe({
            raw: true, next: function (response) {
                if (response.response) {
                    unsaved = false
                    trex.updateControls()
                }
            }
        })
    },
    addSuggestions: function(){
        document.getElementById("button-save-scenario").setAttribute("disabled", true)
        document.getElementById("apply-list-btn").setAttribute("aria-disabled", "true")
        let suggestionButton = document.getElementById("button-save-suggestions")
        suggestionButton.classList.add("rvt-button--loading")
        suggestionButton.setAttribute("aria-busy", true)
        let data = {}
        data.courses = []
        let list = document.getElementsByName("suggestion_course")
        let cartMsg = ""
        for (var i = 0; i < list.length; i++) {
            if (list[i].checked) {
                let course = {}
                course.subject = list[i].dataset.sugsubject
                course.courseNbr = list[i].dataset.sugcrsnbr
                course.descr = list[i].dataset.sugdescr
                course.credits = list[i].dataset.sugcredit
                let row = {}
                row.extOrgId = list[i].dataset.sugtrnsfrsrcid
                row.subject = list[i].dataset.sugsubject
                row.courseNbr = list[i].dataset.sugcrsnbr
                row.credits = list[i].dataset.sugcredit
                data.courses.push(row)
                cartMsg = cartMsg + ` <em>\"${course.subject}-${course.courseNbr} ${course.descr}\"</em>,`


                // Add the course to the specific college entry's courses array, if it doesn't exist. 
                if (state.colleges[row.extOrgId].courses.findIndex(c => (c.subject == course.subject && c.courseNbr == course.courseNbr)) == -1) {

                    // Add course to the specific college's course list. 
                    state.colleges[row.extOrgId].courses.push(course)

                    // Sort by subject then courseNbr (as numeric), unless Descr is preferred.
                    state.colleges[row.extOrgId].courses.sort(function (a, b) {
                        return a.subject.localeCompare(b.subject) || a.courseNbr.localeCompare(b.courseNbr, undefined, { numeric: true })
                    })
                    // Unsaved change. 
                    unsaved = true
                }
            }
        }

        document.getElementById("cart-added-message").innerHTML = cartMsg.substring(0, cartMsg.length-1) + " added."
        document.getElementById("cart-add-alert-success").classList.remove("hidden")

        if (!document.getElementById("summary_section").classList.contains("hidden")) {
            document.getElementById('alert-course-below').innerText = " and applied to the campus selection below"
        } else {
            document.getElementById('alert-course-below').innerText = ""
        }

        // Having updated state, render cart (and show in non-Mobile)
        setTimeout(function () {
            trex.renderCart()
            if (env.user && unsaved) {
                //here's saveCourse
                let url = iu.getUrl("request.html")
                iu.ajaxPost(url, data).subscribe({
                    raw: true, next: function (response) {
                        if (response.response) {
                            unsaved = false
                            trex.updateControls()
                        }
                    }
                })



            } else {
                trex.updateControls()
            }
            //work around for when list doesn't stay open
            const myList = document.querySelector('[data-rvt-dialog="my-list"]')
            myList.open()
        }, 0)
        




    },
    pushCourse: function (extOrgId, subject, courseNbr, descr, credits) {

        let course = {}
            course.subject = subject
            course.courseNbr = courseNbr
            course.descr = descr
            course.credits = credits

        // Add the course to the specific college entry's courses array, if it doesn't exist. 
        if (state.colleges[extOrgId].courses.findIndex(c => (c.subject == course.subject && c.courseNbr == course.courseNbr)) == -1) {

            // Add course to the specifica college's course list. 
            state.colleges[extOrgId].courses.push(course)

            // Sort by subject then courseNbr (as numeric), unless Descr is preferred.
            state.colleges[extOrgId].courses.sort(function (a, b) {
                return a.subject.localeCompare(b.subject) || a.courseNbr.localeCompare(b.courseNbr, undefined, { numeric: true })
            })
        }


        let data = {}
        data.courses = []
        let row = {}
        row.extOrgId = extOrgId
        row.subject = subject
        row.courseNbr = courseNbr
        row.credits = credits
        data.courses.push(row)
        let url = iu.getUrl("request.html")
        iu.ajaxPost(url, data).subscribe({
            raw: true, next: function (response) {
                if (response.response) {
                    unsaved = false
                    trex.updateControls()
                }
            }
        })
    },
    removeCourse: function (event, extOrgId, subject, courseNbr) {
        if (state.colleges[extOrgId]) {
            // filter out the course wanting to be removed. 
            state.colleges[extOrgId].courses = state.colleges[extOrgId].courses.filter(function (course) {
                return !(course.subject == subject && course.courseNbr == courseNbr)
            })

            // if courses for a college is empty, remove college. 
            if (state.colleges[extOrgId].courses.length == 0) {
                delete state.colleges[extOrgId]
            }

            // Removes colleges object form state if empty. 
            if (Object.keys(state.colleges).length == 0)
                delete state.colleges

            unsaved = true
        }
        setTimeout(function (event) {
            trex.renderCart()
            if(env.user && unsaved){
                trex.deleteCourse(extOrgId, subject, courseNbr)
            } else {
                trex.updateControls()
            }
        })
    },
    deleteCourse: function (extOrgId, subject, courseNbr) {
        console.log("deleteCourse")
        let data = {}
        data.extOrgId = extOrgId
        data.subject = subject
        data.courseNbr = courseNbr
        let url = iu.getUrl("request.html")
        iu.ajaxDelete(url, data).subscribe({
            raw: true, next: function (response) {
                if (response.success) {
                    unsaved = false
                    trex.updateControls()
                }
            }
        })
    },    
    addTest: function (element) {
        if(element.getAttribute('aria-disabled')){
            let test = document.getElementById("text-test").value
            let component = document.getElementById("text-component").value
            let score = document.getElementById("select-score").value
            let msg = document.getElementById("disabled-add-test-error-msg")
            if(!test && !component && !score){
                msg.innerHTML = "You must enter a Test, Component/Subject and Score in order to proceed."
            }else if(!component && !score){
                msg.innerHTML = "You must enter a Component/Subject and Score in order to proceed."
            }else if(!score){
                msg.innerHTML = "You must enter a Score in order to proceed."
            }            
            document.getElementById("disabled-add-test-error").classList.remove("hidden")
            return
        } else {
            document.getElementById("disabled-add-test-error").classList.add("hidden")
        }

        let testElement = document.getElementById("text-test")
        let testId = document.getElementById("hidden-test")

        let test = {}
        test.name = testElement.value
        test.id = testId.value

        let componentElement = document.getElementById("text-component")
        let testComponentId = document.getElementById("hidden-component")

        let component = {}
        component.name = componentElement.value
        component.id = testComponentId.value

        let scoreElement = document.getElementById("select-score")
        component.score = scoreElement.value

        // Create Tests associative array if it doesn't exist. 
        if (!state.tests)
            state.tests = {}

        // Create specific test entry in associative array if it doesn't exist.
        if (!state.tests[test.id]) {
            state.tests[test.id] = test
            state.tests[test.id].components = Array()
        }

        // Add the component to the specific test entry's components array, if it doesn't exist.
        if (state.tests[test.id].components.findIndex(c => c.id == component.id) == -1) {

            // Add test to the specific test's components list. 
            state.tests[test.id].components.push(component)

            // Sort by name
            state.tests[test.id].components.sort(function (a, b) {
                return a.name.localeCompare(b.name)
            })

            // Unsaved change. 
            unsaved = true
        }

        // Reset test/component select drop down, and score input. 
        // testElement.selectedIndex = 0;
        componentElement.selectedIndex = 0;
        scoreElement.value = ""

        // document.getElementById("addTestDetail").innerText = "Selected Course"
        document.getElementById("addTestButton").setAttribute("aria-disabled", "true")

        // Successfully Added test alert. 
        document.getElementById("alert-test").innerText = "\"" + test.name + " - " + component.name + "\""
        document.getElementById("test-add-alert-success").classList.remove("hidden")

        let cartMsg = `<em>\"${test.name} - ${component.name}\"</em> added.`
        document.getElementById("cart-added-message").innerHTML = cartMsg
        document.getElementById("cart-add-alert-success").classList.remove("hidden")

        if(!document.getElementById("summary_section").classList.contains("hidden")){
            document.getElementById('alert-test-below').innerText = " and applied to the campus selection below"
        } else {
            document.getElementById('alert-test-below').innerText = ""
        }

        // Having updated state, render cart (and show in non-Mobile)
        setTimeout(function () {
            trex.renderCart()
            if(env.user && unsaved){
                trex.saveTest(test.id, component.id, component.score)
            } else {
                trex.updateControls()
            }
            //work around for when list doesn't stay open
            document.querySelector('[data-rvt-dialog="my-list"]').open()
        }, 0)
    },
    saveTest: function (testId, componentId, score){
        let data = {}
        data.tests = []
        let row = {}
        row.testId = testId
        row.testComponent = componentId
        row.score = score
        data.tests.push(row)

        let url = iu.getUrl("request.html")
        iu.ajaxPost(url, data).subscribe({
            raw: true, next: function (response) {
                if (response.response) {
                    unsaved = false
                    trex.updateControls()
                }
            }
        })
    },    
    removeTest: function (event, testId, componentId) {
        if (state.tests[testId]) {
            // Filter out the component wanting to be removed. 
            state.tests[testId].components = state.tests[testId].components.filter(component => component.id !== componentId)

            // if components for a test is empty, remove the test. 
            if (state.tests[testId].components.length == 0) {
                delete state.tests[testId]
            }

            // Removes tests object form state if empty. 
            if (Object.keys(state.tests).length == 0)
                delete state.tests

            unsaved = true
        }
        setTimeout(function (event) {
            trex.renderCart()
            if(env.user && unsaved){
                trex.deleteTest(testId, componentId)
            } else { 
                trex.updateControls()
            }
        })
    },
    deleteTest: function (testId, componentId) {
        let data = {}
        data.testId = testId
        data.testComponent = componentId
        let url = iu.getUrl("request.html")
        iu.ajaxDelete(url, data).subscribe({
            raw: true, next: function (response) {
                if (response.response) {
                    unsaved = false
                    trex.updateControls()
                }
            }
        })
    },
    removeScenario: function(event, institution, descr){
        document.getElementById("deleteScenario-campusDescr").innerText = descr
        document.getElementById("deleteScenario-institution").value = institution
    },
    deleteScenario: function(){
        let institution = document.getElementById("deleteScenario-institution").value
        console.log("deleteScenario " + institution)

        let button = document.getElementById("delete_scenario_" + institution)
        button.classList.add("rvt-button--loading")
        button.setAttribute("aria-busy", true)

        let data = {}
        data.institution = institution
        let url = iu.getUrl("profile.html")
        iu.ajaxDelete(url, data).subscribe({
            raw: true, next: function (response) {
                if (response.success) {
                    // Remove Tab Button and Panel from UI. 
                    document.getElementById("t-" + institution).remove()
                    document.getElementById(institution + "-tab").remove()
                    
                    // Activate the first tab, if it remains.
                    let tabs = document.querySelectorAll('div.rvt-tabs div.rvt-tabs__panel')
                    let tabSet = document.querySelector('[data-rvt-tabs="tabset-scenarios"]')
                    if(tabs[0]){
                        tabSet.activateTab(tabs[0].id)
                    }else{
                        document.getElementById("no_saved_scenarios_msg").classList.remove("hidden")
                        document.getElementById("no_saved_scenarios_help_msg").classList.add("hidden")
                        tabSet.remove()
                    }
                }
            }
        })
    },
    renderCart: function () {
        console.log("renderCart")

        // Local storage of state for non IU users with no ability to save anything to our DBs. 
        try {
            // Write state to local storage here, since all adders and removers also call renderCart.
            localStorage.setItem("state", JSON.stringify(state))
        } catch (err) {
            // Fail silently. 
        }

        // Clear cart list before re-render. 
        document.getElementById('cartList').replaceChildren()
        // document.getElementById('mobileCartList').replaceChildren()

        // Total List count for NavBar bubble. 
        let totalEntries = 0

        // If there are any Colleges, render them.  
        if (state.colleges) {

            // Sort keys for referencing colleges based on the associated college's name, for rendering into cart.            
            let keys = Object.keys(state.colleges) // keys are trnsfrSrcId
            keys.sort(function (a, b) {
                return state.colleges[a].name.localeCompare(state.colleges[b].name)
            })

            // Render each college's entries in the order sorted above. 
            for (let k of keys) {
                let college = state.colleges[k]

                // Get total List count for NavBar bubble. 
                totalEntries += college.courses.length

                // Append rendered College and it's courses. 
                document.getElementById('cartList').appendChild(renderCartCourseTable(college))
            }
        }

        // If there are any Tests, render them. 
        if (state.tests) {

            // Sort keys for referencing tests based on the associated test's name, for rendering into cart.   
            let keys = Object.keys(state.tests)
            keys.sort(function (a, b) {
                return state.tests[a].name.localeCompare(state.tests[b].name)
            })

            // Containers for rendered rows. 
            let testRows = Array()

            // Render each test's components in the order sorted above. 
            for (let k of keys) {
                let test = state.tests[k]

                // Get total count for NavBar bubble
                totalEntries += test.components.length

                // Render each component for the current test into container
                test.components.forEach(component => {
                    testRows.push(renderCartTestRow(test, component))
                })
            }

            // Append rendered table wrapped test/component rows into the cart list. 
            document.getElementById('cartList').appendChild(renderCartTestTable(testRows))
        }

        // If there is a campus selected in state, update the select-campus drop down. 
        if (state.campus) {
            let campus = document.getElementById("select-campus")
            campus.value = state.campus
        }

        // Set Nav Count
        updateCountBadge(totalEntries)
    },
    updateControls: function (){
        // Updates the Apply List Buttons (disabled vs enabled)
        updateApplyListButton()
    
        // Updates the Save List Buttons (disabled vs enabled)
        updateListButtons()
    
        // Clear Save alerts
        let saveAlert = document.getElementById("save-alert")
        if(saveAlert){
            saveAlert.innerHTML = ""
            saveAlert.classList = "save-alert"
        }
        document.getElementById("cartList").classList.remove("alerted")
    
        // Set confirmation dialog in AUTO_SAVE_DURATION minutes.
        if(!env.user)
            this.setAutoSaveConfirmation()
    
        if (unsaved) {
            showUnsavedAlert()
        }

        // Re-apply list, if list has been applied (summary_section:visible). 
        let summarySection = document.getElementById("summary_section")
        if(!summarySection.classList.contains("hidden") && state.campus && state.campus !== ""){
            // If there's colleges (of courses) or tests, re-Apply
            if(state.colleges || state.tests){
                // if we want to functionally, reapply list "quietly" without UI indicators.
                let data = {}
                if(state.campus){
                    data.campus = state.campus
                }
                if (state.colleges) {
                    data.courses = []
                    for (let key of Object.keys(state.colleges)) {
                        let courses = state.colleges[key].courses
                        courses.forEach(course => {
                            let xcourseNbr= course.courseNbr.replace('_','__')
                            let row = key + '_' + xcourseNbr + '_'+ course.subject
                            data.courses.push(row)
                        })
                    }
                }
                if (state.tests) {
                    data.tests = []
                    for (let key of Object.keys(state.tests)) {
                        let components = state.tests[key].components
                        components.forEach(component => {
                            let row = key + '_' + component.id +'_'+component.score
                            data.tests.push(row)
                        })
                    }
                }
                let url = iu.getUrl("apply.html")
                iu.ajaxGet(url, data).subscribe({
                    raw: true, next: function (response) {
                        util.load("summary_section", response.response)
                        if(env.user)
                            document.getElementById("button-capture-list").classList.add("hidden")
                        else
                            document.getElementById("button-view-scenario").classList.add("hidden")                    
                    }
                })
            // Otherwise display error.
            } else {
                summarySection.classList.add("hidden")            
                let msg = document.getElementById("disabled-applyList-error-msg")
                msg.innerHTML = "Add Courses or Exams in order to proceed."
                document.getElementById("disabled-applyList-error").classList.remove("hidden")
            }
        } 
    },
    applyList: function (element) {
        if(element.getAttribute('aria-disabled')){
            let campus = document.getElementById("select-campus").value
            let msg = document.getElementById("disabled-applyList-error-msg")
            if(!campus){
                msg.innerHTML = "An IU Campus must be selected to proceed."
            }  
            if(!state.courses && !state.tests){
                msg.innerHTML = "Add Courses or Exams in order to proceed."
            }            
            document.getElementById("disabled-applyList-error").classList.remove("hidden")
            return
        } else {
            document.getElementById("disabled-applyList-error").classList.add("hidden")
        }

        console.log("applyList")
        
        let data = {}
        if(state.campus){
            data.campus = state.campus
        }

        if (state.colleges) {
            data.courses = []
            for (let key of Object.keys(state.colleges)) {
                let courses = state.colleges[key].courses
                courses.forEach(course => {
                    let xcourseNbr= course.courseNbr.replace('_','__')
                    let row = key + '_' + xcourseNbr + '_'+ course.subject
                    data.courses.push(row)
                })
            }
        }
        if (state.tests) {
            data.tests = []
            for (let key of Object.keys(state.tests)) {
                let components = state.tests[key].components
                components.forEach(component => {
                    let row = key + '_' + component.id +'_'+component.score
                    data.tests.push(row)
                })
            }
        }

        let apply_button = document.getElementById("apply-list-btn")
        apply_button.classList.add("rvt-button--loading")
        apply_button.setAttribute("aria-busy", true)

        let url = iu.getUrl("apply.html")        
        iu.ajaxGet(url, data).subscribe({
            raw: true, next: function (response) {
                util.load("summary_section", response.response)
                apply_button.classList.remove("rvt-button--loading")
                apply_button.setAttribute("aria-busy", false)
                if(env.user)
                    document.getElementById("button-capture-list").classList.add("hidden")
                else
                    document.getElementById("button-view-scenario").classList.add("hidden")
            }
        })
    },
    saveList: function () {
        console.log("saveList")

        // Save button busy, while saving. 
        toggleSaveButton(true)

        let data = {}
        if (state.colleges) {
            data.courses = []
            for (let key of Object.keys(state.colleges)) {
                let courses = state.colleges[key].courses
                courses.forEach(course => {
                    let row = {}
                    row.extOrgId = key
                    row.subject = course.subject
                    row.courseNbr = course.courseNbr
                    row.credits = course.credits
                    data.courses.push(row)
                })
            }
        }
        if (state.tests) {
            data.tests = []
            for (let key of Object.keys(state.tests)) {
                let components = state.tests[key].components
                components.forEach(component => {
                    let row = {}
                    row.testId = key
                    row.testComponent = component.id
                    row.score = component.score
                    data.tests.push(row)
                })
            }
        }

        console.log(data)

        let saveAlert = document.getElementById("save-alert")
        let url = iu.getUrl("request.html")
        iu.ajaxPost(url, data).subscribe({
            raw: true, next: function (response) {
                if (response.response) {
                    let wrapper = document.createElement("div")
                    wrapper.innerHTML = response.response
                    let alert = wrapper.querySelector("#save-alert")
                    if (alert && saveAlert) {
                        saveAlert.outerHTML = alert.outerHTML
                        document.getElementById("cartList").classList.add("alerted")
                    }
                }

                // Save Button no longer busy. 
                toggleSaveButton(false)

                unsaved = false
                updateApplyListButton()
                updateListButtons()

                // Close save confirmation modal, if open. 
                document.querySelector('[data-rvt-dialog="modal-save-confirmation"]').close()
            }
        })
    },
    saveScenario: function () {
        if(!env.user){
            this.login()
        } else {
			let data = {};
	        data.campus = state.campus;
        
        let list = document.querySelectorAll("dd")
        data.courses = []
        data.tests = []
        for(var i = 0; i < list.length; i++) {
            if((list[i].dataset.ruletransfer==='true' && list[i].dataset.mincoursematch==='true') || list[i].dataset.ruleless==='true'){
                let row = {}
                row.trnsfrSrcId = list[i].dataset.trnsfrsrcid
                row.subject = list[i].dataset.subject
                row.courseNbr = list[i].dataset.crsnbr
                row.credits = list[i].dataset.credit
                row.compSubjectArea = list[i].dataset.compsubjectarea
                row.effdt = list[i].dataset.effdt
                row.trnsfrCmpSeq = list[i].dataset.trnsfrcmpseq
                row.trnsfrEqvlncyCmp = list[i].dataset.trnsfreqvlncycmp
                data.courses.push(row)
            }else if (list[i].dataset.test !=null){
                let row = {}
                row.testId = list[i].dataset.test
                row.testComponent = list[i].dataset.component
                row.trnsfrEqvlncyCmp = list[i].dataset.trnsfreqvlncycmp
                row.tstEqvlncy = list[i].dataset.tsteqvlncy                
                row.score = list[i].dataset.score
                data.tests.push(row)
            }
        }

            let scenarioButton = document.getElementById("button-save-scenario")
            scenarioButton.classList.add("rvt-button--loading")
            scenarioButton.setAttribute("aria-busy", true)

            let url = iu.getUrl("profile.html")
            iu.ajaxPost(url, data).subscribe({
                raw: true,
                next: function next(response) {
                    if (response.success ==="true") {
                        scenarioButton.classList.remove("rvt-button--loading")
                        scenarioButton.setAttribute("aria-busy", false)
                        document.getElementById('save-scenario-alert').classList.remove("hidden")
                    } 
                }
            });
        }
    },
    clearList: function () {
        console.log("clearList")

        localStorage.clear()
        delete state.colleges
        delete state.tests
        unsaved = false
            
        //work around for when dialog doesn't stay open
        setTimeout(function () {
            trex.renderCart()
            if(env.user){           
                let data = {}
                data.cartclear = true
                let url = iu.getUrl("request.html")
            iu.ajaxDelete(url, data).subscribe({
                    raw: true, next: function (response) {
                        if (response.success) {
                            //oh really
                        }
                    }
                })
            } 
            trex.updateControls()            
            // Keep cart open after clearing reopening... 
            document.querySelector('[data-rvt-dialog="my-list"]').open()
        },0)
    },
    clearCourse: function (element) {
        var i = element.parentNode.parentNode.parentNode.rowIndex;
        var table = element.parentNode.parentNode.parentNode.parentNode.parentNode;
        table.deleteRow(i)
        let transfer = element.dataset.ruletransfer

        if(transfer==="true"){
            let credit = element.dataset.transfertotal
            let courseTotal = document.getElementById("totalCredits")
            courseTotal.textContent= courseTotal.textContent - credit
        }
        if(table.rows.length==1)
            table.style.display = 'none'
    },
    clearTest: function (element) {
        var i = element.parentNode.parentNode.parentNode.rowIndex;
        var table = element.parentNode.parentNode.parentNode.parentNode.parentNode;
        table.deleteRow(i) 
        let transfer = element.dataset.transfertotal

        if(transfer>0){
            let credit = element.dataset.transfertotal
            let courseTotal = document.getElementById("totalCredits")
            courseTotal.textContent= courseTotal.textContent - credit
        }

        if(table.rows.length==1)
            table.style.display = 'none'
    },    
    toggleSuggestion: function (element) {
        let crsesuggestions = document.querySelectorAll("input[name='suggestion_course']:checked")
        if (crsesuggestions.length === 0)
            document.getElementById("button-save-suggestions").setAttribute("disabled", true)
        else
            document.getElementById("button-save-suggestions").removeAttribute("disabled")

        let extOrgId = element.dataset.sugtrnsfrsrcid
        let subject = element.dataset.sugsubject
        let courseNbr = element.dataset.sugcrsnbr
        let suggestions = document.querySelectorAll("[data-trnsfrsrcid='" + extOrgId + "']")
        let revealed = false

        for (var i = 0; i < suggestions.length; i++) {
            if (subject === suggestions[i].dataset.subject &&
                courseNbr === suggestions[i].dataset.crsnbr) {
                let trnsfrEqvlncyCmp = suggestions[i].parentNode.parentNode
                if (element.checked) {
                    trnsfrEqvlncyCmp.classList.remove("hidden")
                    revealed = true
                } else {
                    let tce = document.querySelectorAll("[data-trnsfrsrcid='" + suggestions[i].dataset.trnsfrsrcid + "'][data-compsubjectarea='" + suggestions[i].dataset.compsubjectarea + "'][data-compsubjectarea='" + suggestions[i].dataset.compsubjectarea + "'][data-trnsfreqvlncycmp='" + suggestions[i].dataset.trnsfreqvlncycmp + "']")
                    let remain = false
                    for (var a = 0; a < crsesuggestions.length; a++) {
                        for (var b = 0; b < tce.length; b++) {
                            if ( !(subject === tce[b].dataset.subject && courseNbr === tce[b].dataset.crsnbr)) {
                                let qsubject = tce[b].dataset.subject
                                let qcourseNbr = tce[b].dataset.crsnbr
                                if (qsubject === crsesuggestions[a].dataset.sugsubject &&
                                    qcourseNbr === crsesuggestions[a].dataset.sugcrsnbr) {
                                    remain = true;
                                }
                            }
                        }

                    }
                    if (remain === false)
                        trnsfrEqvlncyCmp.classList.add("hidden")
                }
            }
        }
        // 46.25em is used as the width cut off for side-by-side layout of rvt-cols-md
        var isMobile = window.matchMedia( "(max-width: 46.25em)" ).matches;
        if (revealed && isMobile){
            // Scroll to the top of the <section> containing the h2 and dls. 
            document.querySelector("dl[id^='" + extOrgId + "']").parentElement.scrollIntoView()
        }
    }
}

/**
 * Render Unsaved Changes message into My List when there are unsaved changes. Called by {@link renderCart}
 */
function showUnsavedAlert(){
    let innerAlert = `
    <div id="save-alert" class="rvt-inline-alert rvt-inline-alert--standalone rvt-inline-alert--info mobile-full-width print_hide" style="margin-top: 0rem;"> 
        <span class="rvt-inline-alert__icon"> 
            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <g fill="currentColor">
                    <path d="M8,16a8,8,0,1,1,8-8A8,8,0,0,1,8,16ZM8,2a6,6,0,1,0,6,6A6,6,0,0,0,8,2Z" />
                    <path d="M8,12a1,1,0,0,1-1-1V8A1,1,0,0,1,9,8v3A1,1,0,0,1,8,12Z" />
                    <circle cx="8" cy="5" r="1" />
                </g>
            </svg>
        </span> <span class="rvt-inline-alert__message"> Unsaved Changes</span> 
    </div>     
    `;
    let saveAlert = document.getElementById("save-alert")
    if(saveAlert)
        saveAlert.outerHTML = innerAlert
    document.getElementById("cartList").classList.add("alerted")
}

/**
 * Set the Nav Bar count to the supplied, "0" if no parameter. 
 * @param {integer} count 
 */
function updateCountBadge(count = 0) {
    document.getElementById('count-badge').innerText = count
    let saveProfileBtn = document.getElementById('saveProfileBtn')
    if (count == 0) {
        document.getElementById('cartList').innerHTML = '<div class="rvt-ts-18 rvt-p-all-md">No Courses or Exams Added Yet.</div>'
        if(saveProfileBtn)
            saveProfileBtn.setAttribute("disabled", true)
        document.getElementById('count-badge').classList.add("hidden")
    } else {
        if(saveProfileBtn)
            saveProfileBtn.removeAttribute("disabled")
        document.getElementById('count-badge').classList.remove("hidden")
    }
}

/**
 * Updates the Apply List to Campus button based on state (campus selected, courses and/or tests added to "My List")
 */
function updateApplyListButton() {
    // Update the redundant apply buttons. 
    let button = document.getElementById("apply-list-btn")
    let scenario_button = document.getElementById("button-scenario")
    if (state.campus && state.campus !== "" && (state.colleges || state.tests)) {
        document.getElementById("disabled-applyList-error").classList.add("hidden")
        button.removeAttribute("aria-disabled")
        if(scenario_button !=null){
            scenario_button.removeAttribute("disabled")          
            document.getElementById('save-scenario-alert').classList.add("hidden")
            document.getElementById('reapply-alert').classList.add("hidden")
        }
    } else {
        button.setAttribute("aria-disabled", "true")
        if(scenario_button != null){
            scenario_button.setAttribute("disabled", true)
            document.getElementById('save-scenario-alert').classList.add("hidden")
            if(document.getElementsByClassName('responsive scenario_summary').length>0){
                document.getElementById('reapply-alert').classList.remove("hidden")

            }
        }
    }
}

/**
 * Toggle save buttons to busy based on passed argument. 
 * 
 * @param {boolean} busy 
 */
function toggleSaveButton(busy) {
    let buttons = document.querySelectorAll(".save-btn")
    if (busy) {
        buttons.forEach(b => {
            b.classList.add("rvt-button--loading")
            b.setAttribute("aria-busy", true)
        })
    } else {
        buttons.forEach(b => {
            b.classList.remove("rvt-button--loading")
            b.setAttribute("aria-busy", false)
        })
    }
}


/**
 * Updates the Save/Clear List buttons based on state (based on if "My List" is not empty.)
 */
function updateListButtons() {
    // Update the save and list buttons. 
    let buttons = document.querySelectorAll(".list-btn")
    if (state.colleges || state.tests || unsaved) {
        buttons.forEach(b => {
            b.removeAttribute("disabled")
        })
    } else {
        buttons.forEach(b => {
            b.setAttribute("disabled", "")
        })
    }
}


/**
 * Renders a string representation of a <TR> element for a cart entry for the specified course. 
 * All row HTML generation contained herein.
 * 
 * @param {*} collegeId 
 * @param {*} course 
 * @returns 
 */
function renderCartCourseRow(collegeId, course) {
    let row =
        `<tr class="no_bottom_border" id="${course.subject}-${collegeId}-${course.courseNbr}">
            <td>
                ${course.subject}-${course.courseNbr}
            </td>
            <td>
                ${course.descr}
            </td>
            <td>
                <button type="button" class="rvt-button rvt-button--plain rvt-button--small"
                    onClick="trex.removeCourse(event, '${collegeId}','${course.subject}','${course.courseNbr}');">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        viewBox="0 0 16 16" focusable="false" aria-hidden="true">
                        <path fill="currentColor"
                            d="M9.41,8l5.29-5.29a1,1,0,0,0-1.41-1.41L8,6.59,2.71,1.29A1,1,0,0,0,1.29,2.71L6.59,8,1.29,13.29a1,1,0,1,0,1.41,1.41L8,9.41l5.29,5.29a1,1,0,0,0,1.41-1.41Z" />
                    </svg>
                    <span class="rvt-sr-only">Remove Item</span>
                </button>
            </td>
        </tr>`;
    return row.trim();
}

/**
 * Renders a table DOM element for the specified college. All table HTML generation contained herein.
 * 
 * @param {*} college College for whom to generate a Table element. 
 * @returns 
 */
function renderCartCourseTable(college) {
    let courseRows = ""
    college.courses.forEach(course => {
        let row = renderCartCourseRow(college.id, course)
        courseRows = courseRows.concat(row)
    })
    let table =
        `<table class="responsive rvt-table-compact cart_summary">
            <caption>
                ${college.name} <span class="rvt-text-regular">(${college.city}, ${college.state})</span>
            </caption>
            <thead>
                <tr>
                    <th scope="col" class="rvt-sr-only">Course</th>
                    <th scope="col" class="rvt-sr-only">Description</th>
                    <th scope="col" class="rvt-sr-only">Action</th>
                </tr>
            </thead>
            <tbody>
                ${courseRows}
            </tbody>
        </table>`;

    let template = document.createElement('template');
    table = table.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = table;
    return template.content.firstChild;
}

/**
 * Renders a table DOM element for the Tests. All table HTML generation contained herein.
 * 
 * @param {*} rows Array of Test / Component rows
 * @returns 
 */
function renderCartTestTable(rows) {
    let testRows = ""
    rows.forEach(component => {
        testRows = testRows.concat(component)
    })
    let table =
        `<table class="responsive rvt-table-compact cart_summary">
            <caption>
                Tests & Other Credits
            </caption>
            <thead>
                <tr>
                    <th scope="col" class="rvt-sr-only">Test</th>
                    <th scope="col" class="rvt-sr-only">Component & Score</th>
                    <th scope="col" class="rvt-sr-only">Action</th>
                </tr>
            </thead>
            <tbody>
                ${testRows}
            </tbody>
        </table>`;

    let template = document.createElement('template');
    table = table.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = table;
    return template.content.firstChild;
}

/**
 * Render a test / component row. 
 * 
 * @param {*} test Test, for rendering row. 
 * @param {*} component Component, for rendering row. 
 * @returns 
 */
function renderCartTestRow(test, component) {
    let row =
        `<tr class="no_bottom_border" id="${test.id}-${component.id}">
            <td>
                ${test.name}
            </td>
            <td>
                ${component.name}<br>
                Score: ${component.score}
            </td>
            <td>
                <button type="button" class="rvt-button rvt-button--plain rvt-button--small"
                    onClick="trex.removeTest(event, '${test.id}','${component.id}');" data-dropdown-toggle="shopping_cart">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        viewBox="0 0 16 16" focusable="false" aria-hidden="true">
                        <path fill="currentColor"
                            d="M9.41,8l5.29-5.29a1,1,0,0,0-1.41-1.41L8,6.59,2.71,1.29A1,1,0,0,0,1.29,2.71L6.59,8,1.29,13.29a1,1,0,1,0,1.41,1.41L8,9.41l5.29,5.29a1,1,0,0,0,1.41-1.41Z" />
                    </svg>
                    <span class="rvt-sr-only">Remove Item</span>
                </button>
            </td>
        </tr>`;
    return row.trim();
}