export default window.util = {
  isNumber: function(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return false;
  },
  get: function (o, p) {
    var _ = o[p]
    if (_ == null) {
      console.log('WARN: missing ' + p, o)
    }
    return _
  },
  text: function (_) {
    if (_ == null) {
      return null
    }
    var text = _.text()
    if (text == null) {
      console.log('WARN: missing text')
      return null
    }
    return text.trim()
  },
  val: function (_) {
    if (_ == null) {
      return null
    }
    var val = _.val()
    if (val == null) {
      console.log('WARN: missing val')
      return null
    }
    return val.trim()
  },
  find: function (s, q) {
    var _
    if (q == null) {
      _ = document.querySelectorAll(s)
    } else {
      _ = q.querySelectorAll(s)
    }
    if (_.length < 1) {
      console.log('WARN: no match ' + s)
      return null
    } else {
      return _
    }
  },
  load: function (id, data) {
    var s = '#' + id
    var _ = this.find(s)
    if (_ == null) {
      return null
    } else {
      _ = _.item(0)
    }
    var element = document.createElement('div');
    element.innerHTML = data
    var r = this.find(s, element)
    if (r.length < 1) {
      console.log('WARN: missing ' + s, data)
      return null
    }
    _.parentNode.replaceChild(r.item(0), _)
    return this.find(s)
  },
  params: function (p) {
    return p
  }
}
